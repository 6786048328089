@import 'libs/vars';
@import 'libs/functions';
@import 'libs/mixins';
@import 'libs/skel';
@import '../css/font-awesome.min.css';
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300italic,600italic,300,600');


@include skel-breakpoints((
	xlarge:		'(max-width: 1680px)',
	large:		'(max-width: 1280px)',
	medium:		'(max-width: 980px)',
	small:		'(max-width: 736px)',
	xsmall:		'(max-width: 480px)',
	xxsmall:	'(max-width: 360px)'
));

@include skel-layout((
	reset: 'full',
	boxModel: 'border'
));

// Base.

@import 'base/page';
@import 'base/typography';

// Component.

@import 'components/form';
@import 'components/box';
@import 'components/icon';
@import 'components/image';
@import 'components/list';
@import 'components/table';
@import 'components/button';

// Layout.

@import 'layout/bg';
@import 'layout/wrapper';
@import 'layout/header';
@import 'layout/main';
@import 'layout/footer';