#wrapper {
	@include vendor('display', 'flex');
	@include vendor('flex-direction', 'column');
	@include vendor('align-items', 'center');
	@include vendor('justify-content', 'space-between');
	position: relative;
	min-height: 100vh;
	width: 100%;
	padding: 4rem 2rem;
	z-index: 3;

	&:before {
		content: '';
		display: block;
	}

	@include breakpoint(xlarge) {
		padding: 3rem 2rem;
	}

	@include breakpoint(small) {
		padding: 2rem 1rem;
	}

	@include breakpoint(xsmall) {
		padding: 1rem;
	}
}